import {
  useLoaderData,
  ActionFunctionArgs,
  LoaderFunctionArgs,
  Form,
  redirect,
  useFetcher,
} from "react-router-dom";
import toast from "react-hot-toast";

import { createApolloClient } from "../../../providers/ApolloClientFactory";
import { Input, TextArea } from "../../../components/inputs/Input";
import { FormLayout } from "../../../layout/FormLayout";
import { organizationStore } from "../../../store/organization";
import DeleteModal from "../../../components/DeleteModal";
import { SecondaryButton } from "../../../components/Button";
import { usePermissions } from "../../auth/api";
import { userStore } from "../../../store/user";
import {
  BLOGS_POS_BY_ID,
  UPDATE_BLOGS_POS_BY_ID,
} from "./queries_pos_mutations";
import { Blog } from "../type";
import { useState } from "react";
import BlogEditor from "../components/blogs/BlogEditor";

const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getUserStore } = userStore;

export const blogPostPosEditAction = async ({
  params,
  request,
}: ActionFunctionArgs) => {
  const { id } = params;
  const data = await request.formData();
  const user = getUserStore().user;

  try {
    await client.mutate({
      mutation: UPDATE_BLOGS_POS_BY_ID,
      variables: {
        id,
        reference_label: data.get("title"),
        reference_value: data.get("content"),
      },
    });
    toast.success("Blog Post Updated Successfully");
    return redirect("/dashboard");
  } catch (error) {
    toast.error("Failed To Edit Blog Post: " + error);
  }
};

export const blogPostPosEditLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  try {
    const { data } = await client.query({
      query: BLOGS_POS_BY_ID,
      variables: { id },
    });
    return data.dashboard_reference_by_pk;
  } catch (error) {
    console.error("Error fetching organization data:", error);
    throw new Error("Could not fetch organization data");
  }
};
export const BlogPostPosEdit = () => {
  const blogPos = useLoaderData() as Blog;
  const [content, setContent] = useState(blogPos.reference_value);
  const { deletePermission } = usePermissions("organizations");
  const fetcher = useFetcher();

  return (
    <Form action={`/blogpost-pos/${blogPos.id}/edit`} method="put">
      {deletePermission && (
        <div className="flex">
          <div className="ml-auto py-2">
            <DeleteModal
              onConfirm={() => {
                fetcher.submit(
                  {},
                  {
                    method: "delete",
                    action: `/blogpost-pos/${blogPos.id}/delete`,
                  }
                );
              }}
            >
              {({ setOpen }) => (
                <SecondaryButton onClick={() => setOpen(true)}>
                  Delete
                </SecondaryButton>
              )}
            </DeleteModal>
          </div>
        </div>
      )}
      <FormLayout>
        <div className="col-span-4 sm:col-span-3">
          <Input
            label="Title"
            type="text"
            name="title"
            defaultValue={blogPos.reference_label}
          />
        </div>
        <div className="col-span-6">
          <BlogEditor
            content={content}
            setContent={setContent}
            label="Content"
          />
          <Input type="hidden" name="content" value={content} />
        </div>
      </FormLayout>
    </Form>
  );
};

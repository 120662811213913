import React from "react";
import Select from "react-select";

interface SelectOption {
  value: string | number | null;
  label: string;
  disabled?: boolean;
}

interface CustomSelectProps {
  options: SelectOption[];
  multi?: boolean;
  name?: string;
  label?: string;
  title?: string;
  placeholderOnly?: string;
  defaultValue?: any;
  value?: any;
  required?: boolean;
  onChange?: (value: any) => void;
  isDisabled?: boolean;
  closeOnSelect?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
                                                     title,
                                                     placeholderOnly,
                                                     options,
                                                     required,
                                                     multi = false,
                                                     onChange,
                                                     value,
                                                     defaultValue,
                                                     closeOnSelect = true,
                                                     ...props
                                                   }) => {
  const safeValue = multi
      ? Array.isArray(value)
          ? options.filter((option) => value.includes(option.value))
          : []
      : options.find((option) => option.value === value) || null;

  const safeDefaultValue = multi
      ? Array.isArray(defaultValue)
          ? options.filter((option) => defaultValue.includes(option.value))
          : []
      : options.find((option) => option.value === defaultValue) || null;

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      fontSize: "0.875rem",
      fontFamily: "sans-serif",
      borderColor: state.isFocused ? "red" : base.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px red" : base.boxShadow,
      "&:hover": { borderColor: state.isFocused ? "red" : base.borderColor },
    }),
    option: (base: any, state: any) => ({
      ...base,
      fontSize: "0.875rem",
      fontFamily: "sans-serif",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      color: state.isSelected ? "#FFFFFF" : "#000000",
      backgroundColor: state.isSelected ? "red" : state.isFocused ? "darkgray" : "#FFFFFF",
    }),
    input: (base: any) => ({
      ...base,
      fontSize: "0.875rem",
      fontFamily: "sans-serif",
      outline: "none",
      boxShadow: "none",
      "&::placeholder": { color: "transparent" },
    }),
  };

  const handleChange = (selectedOptions: any) => {
    if (multi) {
      const selectedValues = selectedOptions ? selectedOptions.map((opt: SelectOption) => opt.value) : [];
      onChange && onChange(selectedValues);
    } else {
      onChange && onChange(selectedOptions ? selectedOptions.value : null);
    }
  };

  return (
      <div className="w-full">
        {title && (
            <label className="block text-sm font-medium text-gray-700">
              {title}
              {required && <span className="text-red-500"> *</span>}
            </label>
        )}
        <Select
            {...props}
            onChange={handleChange}
            options={options}
            styles={customStyles}
            placeholder={`Select ${title ? title : placeholderOnly}...`}
            isMulti={multi}
            value={value !== undefined ? safeValue : undefined}
            defaultValue={value === undefined ? safeDefaultValue : undefined}
            closeMenuOnSelect={closeOnSelect} // Keeps dropdown open when selecting multiple options
            hideSelectedOptions={false} // Keeps selected options visible in the dropdown
            className="mt-1"
        />
      </div>
  );
};

export default CustomSelect;

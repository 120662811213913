import React, { useState } from "react";
import {
  ActionFunctionArgs,
  Form,
  Link,
  redirect,
  useLocation,
} from "react-router-dom";
import { Input } from "../../components/inputs/Input";
import { LoginButton } from "../../components/Button";
import toast from "react-hot-toast";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import OTPVerification from "./OTPVerification";
import { UPDATE_USER_PASSWORD } from "./mutations";

const client = createApolloClient();

export const ResetPasswordAction = async ({ request }: ActionFunctionArgs) => {
  const body = await request.formData();
  const newPassword = body.get("confirm-password");
  const password = body.get("password");
  const userId = body.get("userId");

  try {
    if (password !== newPassword) {
      toast.error("Passwords do not match.");
      return new Response(
        JSON.stringify({ message: "Passwords do not match." }),
        {
          status: 500,
          headers: { "Content-Type": "application/json" },
        }
      );
    } else {
      await client.mutate({
        mutation: UPDATE_USER_PASSWORD,
        variables: {
          userId,
          newPassword,
        },
      });
      toast.success("Password updated successfully");
      return redirect(`/login`);
    }
  } catch (error) {
    console.error("Unable to update user:", error);
    toast.error("Unable to update user.");
    return new Response(
      JSON.stringify({ message: "Unable to process your request" }),
      {
        status: 500,
        headers: { "Content-Type": "application/json" },
      }
    );
  }
};

const ResetPassword = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const { id } = location.state || {};

  return (
    <div className="flex items-center justify-center h-screen">
      {isAuthenticated ? (
        <Form method="post" action="/login/:id/create-new-password">
          <div className="p-5 bg-white rounded-lg shadow-lg w-full grid gap-4">
            <img
                className="h-40 w-auto justify-self-center"
                src="/static/logo-standard-compressed.svg"
                alt="Ronin"
            />
            <h2 className="block font-medium text-center text-gray-700 text-lg">Create Your Password</h2>
            <Input label="Password" name="password" type="password" />
            <Input
              label="Confirm Password"
              name="confirm-password"
              type="password"
            />
            <input type="hidden" name="userId" value={id} />
            <LoginButton type="submit">Reset Password</LoginButton>
            <Link
              to="/login"
              className="text-sm text-blue-500 hover:text-blue-700 block font-medium text-center"
            >
              Back to Login
            </Link>
          </div>
        </Form>
      ) : (
        <OTPVerification onOTPSubmit={setIsAuthenticated} />
      )}
    </div>
  );
};

export default ResetPassword;

import { gql } from "@apollo/client";

export const PRODUCT_MIX_REPORT = gql`
  query reports_product_mix_query(
    $where: reports_product_mix_bool_exp
  ) {
    reports_product_mix(where: $where, order_by: { pos_name: asc }) {
      unique_id
      # transaction_hour
      transaction_date
      total_units_sold
      token_units_sold
      total_net_sales
      total_tax
      total_gross_sales
      total_discounts
      total_refunds
      rfid_units_sold
      redeemable_token_id
      token_price
      product_type
      organization_name
      pos_name
      organization_id
      # order_id
      location_name
      location_id
      item_price
      event_name
      event_id
      vendor_id
      cash_balance_units_sold
      cash_units_sold
      credit_units_sold
      description
      gross_cash_sales
      gross_cash_balance_sales
      gross_credit_sales
      gross_rfid_sales
      gross_promo_balance_sales
      gross_token_sales
      gl_account_name
      gl_account_number
      total_promo_discounts
      upc
      username
    }
  }
`;


export const PRODUCT_MIX_REPORT_COUNT = gql`
  query reports_product_mix_count(
    $where: reports_product_mix_bool_exp!
  ) {
    total: reports_product_mix_aggregate(where: $where) {
      aggregate {
        count
        __typename
      }
      __typename
    }
  }
`;
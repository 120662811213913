import { usdFormat } from "../../../../utils";
import { PromoCodesReportRow } from "./type";
import { ColDef } from "ag-grid-community";

// Generate column definitions
const generateColDefs = (): ColDef<PromoCodesReportRow>[] => [
  {
    headerName: 'Promo Code Description',
    field: 'promo_to_detail.description',
    filter: 'agTextColumnFilter',
    cellStyle: params => {
      if (params.data?.isTotal) {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Applied Discount Amount',
    valueGetter: p => p.data?.total_promo_discount,
    valueFormatter: params => usdFormat.format((params.value ?? 0) / 100),
    filter: 'agTextColumnFilter',
    cellStyle: params => {
      if (params.data?.isTotal) {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Count',
    valueGetter: p => p.data?.count,
    filter: 'agNumberColumnFilter',
    cellStyle: params => {
      if (params.data?.isTotal) {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
];

export const colDefs: ColDef<PromoCodesReportRow>[] = generateColDefs();

// Function to format data
export const formatData = (data: PromoCodesReportRow[]) => {
  if (!data) return [];

  const groupedRows: Record<string, PromoCodesReportRow> = {};
  let totalDiscounts = 0;
  let totalCount = 0;

  data.forEach((row) => {
    const promoName = row.promo_to_detail?.description || 'Unknown';
    const amount = row.total_promo_discount || 0;

    if (groupedRows[promoName]) {
      // If this promo code exists, add to the existing count and discount amount
      groupedRows[promoName].total_promo_discount += amount;
      groupedRows[promoName].count++; // Increment count
    } else {
      // If this promo code is new, initialize it with current row data
      groupedRows[promoName] = {
        ...row,
        total_promo_discount: amount,
        count: 1, // Initial count is 1 for this promo
        isTotal: false,
      };
    }

    // Accumulate totals for the "Total" row
    totalDiscounts += amount;
    totalCount += 1;
  });

  // Add the total row
  groupedRows['Total'] = {
    promo_to_detail: { description: 'Total' },
    total_promo_discount: totalDiscounts,
    count: totalCount,
    isTotal: true,
    id: 'total',
    username:'',
  };

  // Return grouped rows as an array
  return Object.values(groupedRows);
};

import { Breadcrumb } from "../../components/Breadcrumb";
import { profileAction, profileByIdLoader, ProfileEdit } from "./ProfileEdit";

export const profileRouter = {
  path: "/profile",
  id: "profile",
  element: <ProfileEdit />,
  loader: profileByIdLoader,
  action: profileAction,
  handle: {
    crumb: () => <Breadcrumb name="Profile" href="/profile" />,
  },
};

import { gql } from "@apollo/client";

export const PRODUCT_MIX_SUMMARY_REPORT = gql`
  query reports_product_mix_summary_query(
    $where: reports_product_mix_summary_bool_exp
  ) {
    reports_product_mix_summary(where: $where,order_by: { product_type: asc }) {
      product_type
      redeemable_token_id
      item_price
      redeemable_token_price
      total_gross_sales
      total_tax
      total_refunds
      total_discounts
      total_units_sold
      total_net_sales
      cash_units_sold
      credit_units_sold
      rfid_units_sold
      cash_balance_units_sold
      promo_balance_units_sold
      token_units_sold
      transaction_date
      organization_id
      organization_name
      event_id
      event_name
      location_id
      location_name
      vendor_id
      gross_cash_sales
      gross_cash_balance_sales
      gross_credit_sales
      gross_rfid_sales
      gross_promo_balance_sales
      gross_token_sales
      total_promo_discounts
      username
    }
  }
`;

import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation loginMutation($password: String!, $username: String!) {
    login_dashboard(
      loginInputDashboard: { username: $username, password: $password }
    ) {
      accessToken
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
mutation UpdateUserPassword($userId: Int!, $newPassword: String!) {
      update_users_by_pk(
        pk_columns: { id: $userId },
        _set: {
          password_hash: $newPassword
        }
      ) {
        id
      }
    }
  `;
import { ActionFunctionArgs, Form, redirect } from "react-router-dom";
import toast from "react-hot-toast";
import { createApolloClient } from "../../../providers/ApolloClientFactory";
import { Input, TextArea } from "../../../components/inputs/Input";
import { FormLayout } from "../../../layout/FormLayout";

import { CREATE_BLOG_POS_POST } from "./queries_pos_mutations";
import BlogEditor from "../components/blogs/BlogEditor";
import { useState } from "react";

const client = createApolloClient();

export const blogpostPosCreateAction = async ({
  request,
}: ActionFunctionArgs) => {
  const data = await request.formData();

  try {
    await client.mutate({
      mutation: CREATE_BLOG_POS_POST,
      variables: {
        reference_label: data.get("title"),
        reference_value: data.get("content"),
      },
    });
    toast.success("Blog Post POS Created Successfully");
    return redirect("/dashboard");
  } catch (error: any) {
    const errorType = error?.graphQLErrors?.[0]?.extensions?.code;
    const message = error?.graphQLErrors?.[0]?.message;
    if (errorType === "constraint-violation") {
      toast.error(message);
    } else {
      toast.error("Failed to create blog" + error);
    }
    return null;
  }
};

export const BlogPostPosCreate = () => {
  const [content, setContent] = useState<string>("");

  return (
    <Form action={`/blogpost-pos/create`} method="put">
      <FormLayout>
        <div className="col-span-4 sm:col-span-3">
          <Input label="Title" type="text" name="title" />
        </div>
        <div className="col-span-6">
          <BlogEditor
            content={content}
            setContent={setContent}
            label="Content"
          />
          <Input type="hidden" name="content" value={content} />
        </div>
      </FormLayout>
    </Form>
  );
};

import {
  redirect,
  LoaderFunction,
  LoaderFunctionArgs,
  useLocation,
} from "react-router-dom";
import { userStore } from "../../store/user";
import { PERMISSIONS } from "./api";

const { getState } = userStore;

export const requireAuth = (
  requiredPermissions?: PERMISSIONS[],
  loader?: LoaderFunction
) => {
  return async (args: LoaderFunctionArgs) => {
    const user = getState().user;

    const permissions = getState().permissions as PERMISSIONS;
    // const currentPath = new URL(args.request.url);
    // const fullPath = currentPath.pathname;

    if (!user) {
      return redirect("/login");
    }

    if (
      permissions &&
      requiredPermissions &&
      !requiredPermissions.includes(permissions)
    ) {
      return redirect("/login");
    }
    return loader?.(args) || {};
  };
};

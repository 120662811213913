import { useOutlet } from "react-router-dom";
import { RouteObject } from "react-router-dom";
import ForgotPasswordPage from "./ForgotPassword";
import LoginPage, { loginAction, loginLoader } from "./LoginPage";
import ResetPassword, { ResetPasswordAction } from "./ResetPassword";

function LoginRoot() {
  const outlet = useOutlet();
  return outlet || <LoginPage />;
}

export const authRouter: RouteObject = {
  path: "/login",
  element: <LoginRoot />,
  action: loginAction,
  loader: loginLoader,
  children: [
    {
      index: true,
      element: <LoginPage />,
    },
    {
      path: "forgot-password",
      element: <ForgotPasswordPage />,
      // action: ForgotPasswordAction,
    },
    {
      path: ":id/create-new-password",
      element: <ResetPassword />,
      action: ResetPasswordAction,
    },
  ],
};

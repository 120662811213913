import { gql } from "@apollo/client";

export const UserByPK = gql`
  query UserById($id: Int!) {
    users_by_pk(id: $id) {
      id
      is_active
      username
      created_at
      updated_at
      first_name
      last_name
      role_id
      role {
        name
      }
      phone_number
      email
    }
  }
`;

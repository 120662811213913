import React, { useState } from "react";
import toast from "react-hot-toast";
import { Form, useLocation } from "react-router-dom";
import { Input } from "../../components/inputs/Input";
import { LoginButton } from "../../components/Button";

interface OTPVerificationProps {
  onOTPSubmit: (isAuth: boolean) => void;
}

const OTPVerification: React.FC<OTPVerificationProps> = ({ onOTPSubmit }) => {
  const location = useLocation();
  const { email, otp, phone } = location.state;
  const [userOtp, setUserOtp] = useState("");

  const formatEmail = (email: string) => {
    const parts = email.split("@");
    if (parts.length > 1) {
      const name = parts[0];
      const domain = parts[1];
      return `${name[0]}*****@${domain}`;
    }
    return email;
  };
  const formatPhone = (phone: string) => {
    if (!phone || phone.length < 4) {
      return '***-***-****'; // Handle cases where phone number is too short
    }

    const last4 = phone.slice(-4);
    return `***-***-${last4}`;
  };
  const maskedEmail = email ? formatEmail(email) : "Unknown";
  const maskedPhone = phone ? formatPhone(phone) : "Unknown";

  const verifyOTP = () => {
    if (userOtp === otp) {
      onOTPSubmit(true);
      toast.success("OTP Verified!");
    } else {
      toast.error("Invalid Code, please enter the correct verification code.");
    }
  };

  return (
    <Form
      method="post"
      onSubmit={(e) => {
        e.preventDefault();
        verifyOTP();
      }}
    >
      <div className="p-5 bg-white rounded-lg shadow-lg w-full grid gap-4">
        <img
            className="h-40 w-auto justify-self-center"
            src="/static/logo-standard-compressed.svg"
            alt="Ronin"
        />
        <h2 className="block font-medium text-center text-gray-700 text-sm">
          Enter verification code sent to this {maskedEmail === "Unknown" ? "Phone Number" : "Email"}: <br/>
          {maskedEmail === "Unknown" ? maskedPhone : maskedEmail}
        </h2>
        <Input
            label="Verification Code"
            name="verification"
            type="number"
            maxLength={6}
            value={userOtp}
            onChange={(value) => setUserOtp(value as string)}
            placeholder="Enter 6-digit code"
        />
        <LoginButton type="submit">Verify</LoginButton>
      </div>
    </Form>
  );
};

export default OTPVerification;

import React, { useEffect, useState } from "react";
import { Form, redirect, ActionFunctionArgs, Link } from "react-router-dom";
import toast from "react-hot-toast";
import { Input, PasswordInput } from "../../components/inputs/Input";
import { LoginButton } from "../../components/Button";
import { login } from "./api";
import { userStore } from "../../store/user";

const { getState } = userStore;

export const loginAction = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const username = formData.get("username");
  const password = formData.get("password");

  if (!username || !password) {
    return toast.error("Please enter a username and password.");
  }
  try {
    await login({
      username: username as string,
      password: password as string,
    });
    return redirect("/dashboard");
  } catch (e: any) {
    const errorMessage = "Invalid username or password.";
    return toast.error(errorMessage);
  }
};

export const loginLoader = async () => {
  const user = getState().user;
  if (user) {
    return redirect("/dashboard");
  }
  return {};
};

const LoginPage = () => {
  const [deployDate, setDeployDate] = useState<string | null>(null);
  useEffect(() => {
    async function fetchDeployDate() {
      const response = await fetch(
        "https://api.vercel.com/v6/deployments?teamId=team_UXkC3yKuDivTqKgMln3DsqmU",
        {
          headers: {
            Authorization: `Bearer ${import.meta.env.VITE_DEPLOY_DATE_API_KEY}`,
          },
        }
      );
      const data = await response.json();
      if (data.deployments && data.deployments.length > 0) {
        setDeployDate(new Date(data.deployments[0].createdAt).toLocaleString());
      }
    }
    fetchDeployDate();
  }, []);

  return (
    <div className="flex items-center justify-center h-screen">
      <Form method="post" action="/login">
        <div className="p-5 bg-white rounded-lg shadow-lg w-full grid gap-4">
          <img
            className="h-40 w-auto justify-self-center"
            src="/static/logo-standard-compressed.svg"
            alt="Ronin"
          />
          <Input label="Username" name="username" type="text" />
          <PasswordInput label="Password" name="password" />
          <LoginButton type="submit">Login</LoginButton>
          <Link
            to="/login/forgot-password"
            className="block text-sm font-medium text-blue-500 hover:text-blue-700 text-center"
          >
            Forgot Password?
          </Link>
          <label className="block text-sm font-medium text-gray-700">
            Last Deployed: {deployDate}
          </label>
        </div>
      </Form>
    </div>
  );
};

export default LoginPage;

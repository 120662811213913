
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], 
  // ['blockquote', 'code-block'],

  [{ 'header': 1 }, { 'header': 2 }],             
  // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  // [{ 'script': 'sub'}, { 'script': 'super' }],     
  // [{ 'indent': '-1'}, { 'outdent': '1' }],  
  // [{ 'direction': 'rtl' }],                 

  [{ 'size': ['small', false, 'large', 'huge'] }],  
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],   
  [{ 'font': [] }],
  // [{ 'align': [] }],

  // ['clean'],                         
  ['link', 'image', 'video']                        
];

async function uploadImage(file) {
  const url = 'https://<AWS3-bucket-service-url>';
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    const data = await response.json();
    return data.url;
  } catch (error) {
    console.error('Error uploading image: ', error);
  }
}

const handleImageUpload = () => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();
  input.onchange = async () => {
    const file = input.files[0];
    const url = await uploadImage(file);
    const range = this.quill.getSelection(true);
    this.quill.insertEmbed(range.index, 'image', url, Quill.sources.USER);
    input.value = '';
  };
};

const modules = {
  toolbar: {
    container: toolbarOptions,
    handlers: {
      'image': handleImageUpload,
    }
  },
};

const BlogEditor = ({ content, setContent, label }) => {
  return (
    <div>
      {label && <label htmlFor="blog-editor">{label}</label>}
      <ReactQuill
        theme="snow"
        value={content}
        onChange={setContent}
        modules={modules}
        id="blog-editor"
      />
    </div>
  );
};

export default BlogEditor;
import React, { useEffect, useState } from "react";
import ReportHeader from "../../../reports/components/ReportHeader";
import { createApolloClient } from "../../../../providers/ApolloClientFactory";
import { userStore } from "../../../../store/user";
import { organizationStore } from "../../../../store/organization";
import { eventStore } from "../../../../store/event";
import { REPORTS_LOCATION } from "../../../reports/point-of-sale/location-sales-report/fragment";
import { PRODUCT_MIX_REPORT } from "../../../reports/point-of-sale/product-mix-report/fragment";
import Loader from "../../../../components/Loader";
import { formatData as productMixFormatter } from "../../../reports/point-of-sale/product-mix-report/helpers";
import { formatData as clerkFormatter } from "../../../reports/point-of-sale/clerk-report/helper";
import { formatData as locationFormatter } from "../../../reports/point-of-sale/location-sales-report/helpers";
import { REPORTS_CLERK } from "../../../reports/point-of-sale/clerk-report/fragment";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventState } = eventStore;

function PerformersCard() {
  const [topGrossingItems, setTopGrossingItems] = useState<any[]>([]);
  const [topSellingItems, setTopSellingItems] = useState<any[]>([]);
  const [topClerk, setTopClerk] = useState<any[]>([]);
  const [topGrossingLocations, setTopGrossingLocations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const usdFormatter = (value: number) => {
    return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };

  const numberFormatter = (value: number) => {
    return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const organizationId = getOrganizationState().organizationId;
  const { eventId } = getEventState();
  const user = getState().user;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        let where: any = {
          organization_id: { _eq: organizationId },
        };

        if (user?.events) {
          where = {
            ...where,
            event_id: { _in: user?.events },
          };
        }
        if (user?.vendors) {
          where = {
            ...where,
            vendor_id: {
              _in: user?.vendors,
            },
          };
        }

        const locationWhere: any = {
          event: {
            organization_id: {
              _eq: organizationId,
            },
          },
        };

        if (user?.events) {
          locationWhere.event = {
            ...locationWhere.event,
            id: {
              _in: user?.events,
            },
          };
        }

        const { data: productMixData } = await client.query({
          query: PRODUCT_MIX_REPORT,
          variables: { where },
        });

        const { data: clerkData } = await client.query({
          query: REPORTS_CLERK,
          variables: { where },
        });

        const { data: locationData } = await client.query({
          query: REPORTS_LOCATION,
          variables: { where },
        });

        const getTopSortedItems = (data: any[], key: string, limit: number) =>
          [...data].sort((a, b) => b[key] - a[key]).slice(0, limit);

        const filterRows = (data: any[]) =>
          data.filter((row) => row.id !== "total-row");

        const sortedAndFilteredData = (
          data: any[],
          key: string,
          formatter: (data: any[]) => any[],
          limit: number
        ) => filterRows(getTopSortedItems(formatter(data), key, limit));

        const filteredGrossProduct = sortedAndFilteredData(
          productMixData.reports_product_mix,
          "total_gross_sales",
          productMixFormatter,
          5
        );
        const filteredUnitProduct = sortedAndFilteredData(
          productMixData.reports_product_mix,
          "total_units_sold",
          productMixFormatter,
          5
        );
        const filteredClerk = sortedAndFilteredData(
          clerkData.reports_financial_hourly,
          "total_gross_sales",
          clerkFormatter,
          5
        );
        const filteredGrossLocation = sortedAndFilteredData(
          locationData.reports_financial_hourly,
          "total_gross_sales",
          locationFormatter,
          5
        );

        setTopGrossingItems(filteredGrossProduct);
        setTopSellingItems(filteredUnitProduct);
        setTopClerk(filteredClerk);
        setTopGrossingLocations(filteredGrossLocation);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [client, organizationId, user?.events, eventId]);

  return (
    <div className="w-full overflow-hidden  bg-white shadow text-center">
      <div className="grid grid-cols-1 rounded-lg gap-4 gap-y-10 justify-center bg-gray-200 p-5 m-5">
        <div className="grid justify-items-center">
          <ReportHeader title="Top Performers" />
        </div>
        <div className="w-full overflow-hidden rounded-lg bg-white shadow grid grid-cols-1 md:grid-cols-4 gap-4 p-8">
          <div>
            <div className="text-xl font-semibold text-gray-900 pb-2">
              <h1>Top Grossing Items</h1>
            </div>
            <div>
              <ul className="divide-y divide-gray-200">
                {isLoading ? (
                  <Loader />
                ) : topGrossingItems && topGrossingItems.length > 0 ? (
                  topGrossingItems.map((item, index) => (
                    <li
                      key={index}
                      className="flex flex-wrap justify-between items-center py-4"
                    >
                      <p className="flex-1 mx-4 text-sm font-medium text-gray-500 break-words">
                        {item.pos_name} {" ("}
                        {usdFormatter(item.item_price)}
                        {")"} - {usdFormatter(item.total_gross_sales)}
                      </p>
                    </li>
                  ))
                ) : null}
              </ul>
            </div>
          </div>
          <div>
            <div className="text-xl font-semibold text-gray-900 pb-2">
              <h1>Top Selling Items</h1>
            </div>
            <div>
              <ul className="divide-y divide-gray-200">
                {isLoading ? (
                  <Loader />
                ) : topSellingItems && topSellingItems.length > 0 ? (
                  topSellingItems.map((item, index) => (
                    <li
                      key={index}
                      className="flex flex-wrap justify-between items-center py-4"
                    >
                      <p className="flex-1 mx-4 text-sm font-medium text-gray-500 break-words">
                        {item.pos_name} {" ("}
                        {usdFormatter(item.item_price)}
                        {")"} - {numberFormatter(item.total_units_sold)}
                      </p>
                    </li>
                  ))
                ) : null}
              </ul>
            </div>
          </div>
          <div>
            <div className="text-xl font-semibold text-gray-900 pb-2">
              <h1>Top Grossing Clerks</h1>
            </div>
            <div>
              <ul className="divide-y divide-gray-200">
                {isLoading ? (
                  <Loader />
                ) : topClerk && topClerk.length > 0 ? (
                  topClerk.map((clerk, index) => (
                    <li
                      key={index}
                      className="flex flex-wrap justify-between items-center py-4"
                    >
                      <p className="flex-1 mx-4 text-sm font-medium text-gray-500 break-words">
                        {clerk.username} -{" "}
                        {usdFormatter(clerk.total_gross_sales)}
                      </p>
                    </li>
                  ))
                ) : null}
              </ul>
            </div>
          </div>
          <div>
            <div className="text-xl font-semibold text-gray-900 pb-2">
              <h1>Top Grossing Locations</h1>
            </div>
            <div>
              <ul className="divide-y divide-gray-200">
                {isLoading ? (
                  <Loader />
                ) : topGrossingLocations && topGrossingLocations.length > 0 ? (
                  topGrossingLocations.map((item, index) => (
                    <li
                      key={index}
                      className="flex flex-wrap justify-between items-center py-4"
                    >
                      <p className="flex-1 mx-4 text-sm font-medium text-gray-500 break-words">
                        {item.location_name} -{" "}
                        {usdFormatter(item.total_gross_sales)}
                      </p>
                    </li>
                  ))
                ) : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PerformersCard;

import { useLoaderData, LoaderFunctionArgs } from "react-router-dom";
import { PROMO_CODES_REPORT} from "./fragment";
import { colDefs } from "./helpers";
import { ReportFilters } from "../../components/ReportFilters";
import ReportHeader from "../../components/ReportHeader";
import { userStore } from "../../../../store/user";
import { createApolloClient } from "../../../../providers/ApolloClientFactory";
import { organizationStore } from "../../../../store/organization";
import { PromoCodesReportRow } from "./type";
import React, { useRef } from "react";
import { eventStore } from "../../../../store/event";
import { GridApi, SizeColumnsToContentStrategy } from "ag-grid-community";
import { SecondaryButton } from "../../../../components/Button";
import { AgGridReact } from "ag-grid-react";
import { formatData } from "./helpers";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventState } = eventStore;

export const promoCodesReportLoader = async ({
  request,
}: LoaderFunctionArgs) => {
  const rawOrganizationId = getOrganizationState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const { eventId } = getEventState();
  const user = getState().user;

  const search = new URL(request.url);
  const where = search.searchParams.get("where");
  let whereVariable: any = where ? JSON.parse(where) : {};
  if (organizationId && organizationId !== 0) {
    whereVariable = {
      ...whereVariable,
      organization_id: {
        _eq: organizationId,
      },
    };
  }
  if (user?.vendors) {
    whereVariable = {
      ...whereVariable,
      vendor_id: {
        _in: user?.vendors,
      },
    };
  }

  if (eventId) {
    whereVariable = {
      ...whereVariable,
      event_id: {
        _eq: eventId,
      },
    };
  } else if (user?.events) {
    whereVariable = {
      ...whereVariable,
      event_id: {
        _in: user?.events,
      },
    };
  }
  whereVariable = {
    ...whereVariable,
    payment_status: {
      _eq: "approved",
    },
    total_promo_discount: {
      _gt: 0
    }
  };
  console.log({whereVariable})
  const { data } = await client.query({
    query: PROMO_CODES_REPORT,
    variables: { where: whereVariable },
  });
  console.log(data.reports_financial || []);
  return data.reports_transactions || [];

};

export const PromoCodesReport = () => {
  const data = useLoaderData() as PromoCodesReportRow[];
  console.log({ data });
  const newData: PromoCodesReportRow[] = formatData(data);
  const initialUsernamesRef = useRef<{ label: string; value: string }[] | null>(null);
  if (!initialUsernamesRef.current) {
    initialUsernamesRef.current = [
      ...new Set(data.map((row) => row.username))
    ].map((username) => ({
      label: username,
      value: username
    }));
  }
  const gridRef = useRef<GridApi>();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `PromoCodesReport_${timestamp}.csv`,
        columnSeparator: ",",
        skipHeader: false,
        allColumns: true,
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };

  return (
    <>
      <div className="bg-white px-4 pb-1 mb-3 rounded-md">
        <ReportHeader title="Promo Codes Report" />
        <ReportFilters
          includeDates
          includeTime
          includeLocations
          includeUsernames
          includeUsername={initialUsernamesRef.current}
          includeVendors
          customQueryPaths={{
            dateFrom: "_and[0].transaction_hour",
            dateTo: "_and[1].transaction_hour",
          }}
        />
        <div className="flex">
          <div className="ml-auto">
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
          </div>
        </div>
      </div>
      <div style={{ height: "60vh" }}>
        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: "100%" }}
        >
          <AgGridReact
            rowData={newData}
            columnDefs={colDefs}
            rowHeight={rowHeight}
            autoSizeStrategy={autoSizeStrategy}
            pagination={true}
            gridOptions={gridOptions}
            paginationPageSize={100}
            // pinnedBottomRowData={totalRow}
            onGridReady={(params) => (gridRef.current = params.api)} // Set GridApi reference
          />
        </div>
      </div>
    </>
  );
};

import { gql } from "@apollo/client";

export const UPDATE_ORGANIZATION_USER = gql`
  mutation UpdateOrganizationUser(
    $id: Int!
    $password_hash: String!
    $email: String!
    $first_name: String!
    $last_name: String!
    $phoneNumber: String!
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: {
        password_hash: $password_hash
        email: $email
        first_name: $first_name
        last_name: $last_name
        phone_number: $phoneNumber
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ORGANIZATION_USER_NO_PASSWORD = gql`
  mutation UpdateOrganizationUser(
    $id: Int!
    $email: String!
    $first_name: String!
    $last_name: String!
    $phoneNumber: String!
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: {
        email: $email
        first_name: $first_name
        last_name: $last_name
        phone_number: $phoneNumber
      }
    ) {
      id
    }
  }
`;
import React from "react";
import { Form, Link, useNavigate } from "react-router-dom";
import { Input } from "../../components/inputs/Input";
import { LoginButton } from "../../components/Button";
import { forgotPassword } from "./api";
import toast from "react-hot-toast";

interface ForgotPasswordActionArgs {
  username: string;
  navigate: ReturnType<typeof useNavigate>;
}


export const ForgotPasswordAction = async ({ username, navigate }: ForgotPasswordActionArgs) => {
  try {
    const response = await forgotPassword({ username });
    console.log("Response", {response});
    if (response.success) {
      toast.success(response.message);
      navigate(`/login/${response.id}/create-new-password`, {
        state: {
          id: response.id,
          email: response.email,
          phone: response.phone,
          success: true,
          message: "OTP sent successfully. Please check your email.",
          otp: response.otp,
        },
      });
    } else {
      toast.error(response.message);
      return new Response(JSON.stringify({ message: response.message }), {
        status: 404,
        headers: { "Content-Type": "application/json" },
      });
    }
  } catch (e) {
    console.error("Error processing the forgot password request:", e);
    return new Response(
        JSON.stringify({ message: "An error occurred while processing your request." }),
        {
          status: 500,
          headers: { "Content-Type": "application/json" },
        }
    );
  }
};

const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const handleForgotPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const usernameInput = event.currentTarget.elements.namedItem("username") as HTMLInputElement;
    const username = usernameInput?.value.trim();

    if (username) {
      await ForgotPasswordAction({ username, navigate });
    } else {
      toast.error("Please enter a username.");
    }
  };

  return (
      <div className="flex items-center justify-center h-screen">
        <Form method="post" action="/login/forgot-password" onSubmit={handleForgotPassword}>
          <div className="p-5 bg-white rounded-lg shadow-lg w-full grid gap-4">
            <img
                className="h-40 w-auto justify-self-center"
                src="/static/logo-standard-compressed.svg"
                alt="Ronin"
            />
            <Input label="Username" name="username" type="text" />
            <LoginButton type="submit">Send Reset Link</LoginButton>
            <Link to="/login" className="block text-sm font-medium text-center text-blue-500 hover:text-blue-700">
              Back to Login
            </Link>
          </div>
        </Form>
      </div>
  );
};

export default ForgotPasswordPage;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { getGreeting } from "./utils";
import { useUserStore } from "../../store/user";
import SalesCard from "./components/cards/SalesCard";
import SecondSalesCard from "./components/cards/SecondSalesCard";
import PerformersCard from "./components/cards/PerformersCard";
import WhatsNewCard from "./components/cards/WhatsNewCard";
import { ToggleButton } from "../../components/Button";
import AdminCard from "./components/cards/AdminCard";

export const Dashboard = () => {
  const user = useUserStore((state) => state.user);
  const [selectedCard, setSelectedCard] = useState("first_second");

  const handleToggleCard = (cardKey: any) => {
    setSelectedCard(cardKey);
  };
  return (
    <div>
      <div className="bg-white shadow">
        <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
            <div className="min-w-0 flex-1">
              <div className="flex items-center">
                <div>
                  <div className="flex items-center">
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                      {getGreeting()}, {user?.username}{" "}
                      {user?.vendor?.name ? `(${user.vendor.name})` : ""}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-white shadow flex justify-start flex-wrap">
          <div className="ml-5">
            <ToggleButton
              isSelected={selectedCard === "first_second"}
              onClick={() => handleToggleCard("first_second")}
            >
              Sales At A Glance
            </ToggleButton>
          </div>
          <ToggleButton
            isSelected={selectedCard === "third"}
            onClick={() => handleToggleCard("third")}
          >
            Top Performers
          </ToggleButton>
          <ToggleButton
            isSelected={selectedCard === "forth"}
            onClick={() => handleToggleCard("forth")}
          >
            Whats New
          </ToggleButton>
          {user?.role_id === 1 && (
            <ToggleButton
              isSelected={selectedCard === "admin"}
              onClick={() => handleToggleCard("admin")}
            >
              Admin
            </ToggleButton>
          )}
        </div>
        <div className="cards-container">
          {selectedCard === "first_second" && (
            <>
              <SalesCard />
              <SecondSalesCard />
            </>
          )}
          {selectedCard === "third" && <PerformersCard />}
          {selectedCard === "forth" && <WhatsNewCard />}
          {selectedCard === "admin" && <AdminCard />}
        </div>
      </div>
    </div>
  );
};

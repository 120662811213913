import React, { useEffect, useCallback, useState } from "react";
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Cell,
} from "recharts";
import ReportHeader from "../../../reports/components/ReportHeader";
import { createApolloClient } from "../../../../providers/ApolloClientFactory";
import { userStore } from "../../../../store/user";
import { organizationStore } from "../../../../store/organization";
import { eventStore } from "../../../../store/event";
import { REPORTS_FINANCIAL } from "../../../reports/point-of-sale/financial-report/fragment";
import Loader from "../../../../components/Loader";
import moment from "moment-timezone";
import { FinancialReportRow } from "../../../reports/point-of-sale/financial-report/type";
import { formatData } from "../../../reports/point-of-sale/financial-report/helpers";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventState } = eventStore;

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

interface FinancialData {
  vendor_name: string;
  vendor_id: number;
  transaction_date: string;
  total_transaction_count: number;
  total_tips: number;
  total_tax: number;
  total_refunds: number;
  net_refunds: number;
  total_gross_sales: number;
  total_discounts: number;
  total_digital_surcharge: number;
  token_transaction_count: number;
  rfid_transaction_count: number;
  promo_balance_transaction_count: number;
  organization_name: string;
  organization_id: number;
  net_promo_balance_sales: number;
  menu_name: string;
  menu_id: number;
  location_name: string;
  location_id: number;
  gross_token_sales: number;
  gross_rfid_tips: number;
  gross_rfid_sales: number;
  gross_promo_balance_tips: number;
  gross_promo_balance_sales: number;
  gross_credit_tips: number;
  gross_credit_sales: number;
  gross_cash_sales: number;
  gross_cash_balance_tips: number;
  gross_cash_balance_sales: number;
  event_name: string;
  event_id: number;
  credit_transaction_count: number;
  cash_transaction_count: number;
  cash_balance_transaction_count: number;
}

function SecondSalesCard() {
  const [financialData, setFinancialData] = useState<FinancialReportRow[]>([]);
  const [pieChartData, setPieChartData] = useState<FinancialData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [mostRecentDate, setMostRecentDate] = useState<string | null>(null);

  const organizationId = getOrganizationState().organizationId;
  const { eventId } = getEventState();
  const user = getState().user;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        let where: any = {
          organization_id: { _eq: organizationId },
        };
        if (user?.events) {
          where = {
            ...where,
            event_id: { _in: user?.events },
          };
        }
        if (user?.vendors) {
          where = {
            ...where,
            vendor_id: { _in: user?.vendors },
          };
        }

        const { data } = await client.query({
          query: REPORTS_FINANCIAL,
          variables: { where },
        });

        setFinancialData(data.reports_financial);
        setPieChartData(data.reports_financial);
      } catch (error) {
        console.error("Error fetching financial data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [organizationId, eventId, user]);

  const BarChartComponent = ({
    rows,
    selectedDataKey,
  }: {
    rows: FinancialReportRow[];
    selectedDataKey: string;
  }) => {
    if (!rows || rows?.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-full pb-4">
          <div className="text-gray-500 text-sm">No data to display</div>
        </div>
      );
    }

    // Filter out null or undefined items
    const filteredData = rows.filter(
      (item) => item !== null && item !== undefined
    );

    const limitedData = filteredData.slice(0, 5);
    const reversedData = limitedData.reverse();
    const usdFormatter = (value: number) => {
      return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    };

    const dateFormatter = (value: string) => {
      const date = moment.utc(value); // Parse as UTC
      return date.format("ddd, MMM DD, YYYY");
    };

    function formatDataKey(key: string) {
      return key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }

    const maxDateLength = Math.max(
      ...reversedData.map((item) => item.transaction_date?.length || 0)
    );

    const angleInRadians = (Math.abs(-30) * Math.PI) / 180;
    const textHeight = Math.sin(angleInRadians) * maxDateLength * 9.5;
    const xAxisHeight = textHeight > 50 ? textHeight : 50;

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={reversedData}
          margin={{
            top: 30,
            right: 10,
            left: 0,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="transaction_date"
            tickFormatter={dateFormatter}
            tick={{
              fontSize: "0.875rem",
              fontWeight: 600,
              fill: "#4a5568",
            }}
            {...(reversedData.length > 5 && {
              interval: 0,
              angle: -35,
              textAnchor: "end",
              height: xAxisHeight,
            })}
          />
          <YAxis
            width={80}
            tickFormatter={usdFormatter}
            tick={{
              fontSize: "0.875rem",
              fontWeight: 600,
              fill: "#4a5568",
            }}
          />
          <Tooltip
            contentStyle={{
              fontSize: "0.875rem",
              fontWeight: 600,
              backgroundColor: "#fff",
              border: "1px solid #e2e8f0",
              color: "#4a5568",
            }}
            formatter={usdFormatter}
          />
          <Bar
            dataKey={selectedDataKey}
            fill="#B71C1C"
            name={formatDataKey(selectedDataKey)}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };
  const formattedData = formatData(financialData);
  const chartRows = formattedData.filter(
    (row) => row.transaction_date !== "Total"
  );
  useEffect(() => {
    if (financialData.length > 0) {
      const todayStart = moment().tz(timezone).startOf("day").utc();
      let mostRecentDate = null;

      const todayData = financialData.filter((item) => {
        if (!item.transaction_date) return false;
        const transactionMoment = moment(item.transaction_date)
          .utc()
          .startOf("day");
        return transactionMoment.isSame(todayStart, "day");
      });

      if (todayData.length === 0) {
        const financialDataCopy = [...financialData];
        financialDataCopy.sort((a, b) => {
          return moment(b.transaction_date).diff(moment(a.transaction_date));
        });
        mostRecentDate = moment(financialDataCopy[0]?.transaction_date)
          .utc()
          .startOf("day")
          .format("MM-DD-YYYY");
      } else {
        mostRecentDate = moment().tz(timezone).format("MM-DD-YYYY");
      }

      setMostRecentDate(mostRecentDate); // Update state once
    }
  }, [financialData]);
  const generatePieData = (financialData: FinancialData[]) => {
    const todayStart = moment().tz(timezone).startOf("day").utc();
    let todayData = financialData.filter((item) => {
      if (!item.transaction_date) {
        return false;
      }
      const transactionMoment = moment(item.transaction_date)
        .utc()
        .startOf("day");

      return transactionMoment.isSame(todayStart, "day");
    });

    if (todayData.length === 0) {
      const financialDataCopy = [...financialData];

      financialDataCopy.sort((a, b) => {
        return moment(b.transaction_date).diff(moment(a.transaction_date));
      });

      const mostRecentDate = moment(
        financialDataCopy[0]?.transaction_date
      ).startOf("day");
      todayData = financialDataCopy.filter((item) => {
        if (!item.transaction_date) {
          return false;
        }
        const transactionMoment = moment(item.transaction_date).startOf("day");
        return transactionMoment.isSame(mostRecentDate, "day");
      });
    }
    const cashSales = todayData.reduce(
      (sum, item) => sum + (item.gross_cash_sales || 0),
      0
    );
    const creditSales = todayData.reduce(
      (sum, item) => sum + (item.gross_credit_sales || 0),
      0
    );

    const promoSales = todayData.reduce(
      (sum, item) => sum + (item.gross_promo_balance_sales || 0),
      0
    );
    const rfidSales = todayData.reduce(
      (sum, item) => sum + (item.gross_rfid_sales || 0),
      0
    );
    const tokenSales = todayData.reduce(
      (sum, item) => sum + (item.gross_token_sales || 0),
      0
    );
    const cashBalanceSales = todayData.reduce(
      (sum, item) => sum + (item.gross_cash_balance_sales || 0),
      0
    );

    const pieData = [
      { name: "Cash Sales", value: cashSales },
      { name: "Credit Sales", value: creditSales },
      { name: "RFID Sales", value: rfidSales },
      { name: "Promo Balance Sales", value: promoSales },
      { name: "Token Sales", value: tokenSales },
      { name: "Cash Balance Sales", value: cashBalanceSales },
    ];

    const filteredPieData = pieData.filter((data) => data.value > 0);
    const formattedPieData = filteredPieData.map((data) => ({
      name: data.name,
      value: data.value,
      formattedValue: data.value,
    }));
    return formattedPieData.length > 0 ? formattedPieData : null;
  };

  const pieData = generatePieData(pieChartData);
  const onPieEnter = useCallback(
    (_: string, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const COLORS = [
    "#B71C1C",
    "#000000",
    "#1C1CB7",
    "#7C1CB7",
    "#B76A1C",
    "#1C96B7",
  ];
  return (
    <div className="w-full overflow-hidden grid grid-cols-1 md:grid-cols-2 gap-3 p-5 bg-white shadow">
      <div className="w-full rounded-lg grid grid-cols-1 md:grid-cols-1 bg-gray-200 shadow">
        <div className="p-3 px-4 grid justify-items-center">
          <ReportHeader
            title={`Gross Sales by Payment Method for ${mostRecentDate}`}
            toolTipShow={true}
            tooltipText={
              "This pie chart represents the total revenue by payment method for the selected event. Each section of the chart corresponds to a different payment method, displaying the relative contribution of each method (e.g., cash, credit, RFID) to the overall revenue. The size of each slice is proportional to the amount of revenue generated by that payment type, allowing a clear visual comparison between the different methods. The chart helps identify which payment method is driving the most revenue."
            }
          />
        </div>
        <div className="pl-3 h-80">
          {isLoading ? (
            <div className="w-full flex items-center justify-center">
              <Loader />
            </div>
          ) : pieData ? (
            <ResponsiveContainer
              className="grid bg-white rounded-md"
              width="98%"
              height="97%"
            >
              <PieChart width={400} height={400}>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  outerRadius="75%"
                  dataKey="value"
                  labelLine={false}
                  label={({
                    cx,
                    cy,
                    midAngle,
                    innerRadius,
                    outerRadius,
                    percent,
                  }) => {
                    const radius =
                      innerRadius + (outerRadius - innerRadius) / 2;
                    const x =
                      cx + radius * Math.cos((-midAngle * Math.PI) / 180);
                    const y =
                      cy + radius * Math.sin((-midAngle * Math.PI) / 180);
                    return (
                      <text
                        x={x}
                        y={y}
                        fill="white"
                        textAnchor="middle"
                        dominantBaseline="central"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        {`${(percent * 100).toFixed(1)}%`}
                      </text>
                    );
                  }}
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  contentStyle={{
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    backgroundColor: "#fff",
                    border: "1px solid #e2e8f0",
                    color: "#4a5568",
                  }}
                  formatter={(value: number, name: string) =>
                    `$${(value / 100).toFixed(2)}`
                  }
                />
                <Legend
                  layout="vertical"
                  align="left"
                  verticalAlign="middle"
                  formatter={(value, entry) => {
                    const labelName = (entry.payload as any)?.name;
                    const labelValue =
                      pieData.find((d) => d.name === labelName)?.value || 0;
                    return `${value} - $${(labelValue / 100).toFixed(2)}`;
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <span>No data available for today</span>
            </div>
          )}
        </div>
      </div>
      <div className="w-full rounded-lg grid bg-gray-200 shadow">
        <div className="p-1 px-4 grid justify-items-center">
          <ReportHeader
            title="Net Sales by Day"
            toolTipShow={true}
            tooltipText={
              "This report shows daily net sales, broken down hour-by-hour from 5am to 5am the next day. Each row is a distinct day, with columns representing net sales for each hour. It offers insight into hourly sales trends and daily sales momentum. Perfect for tracking and comparing sales performance throughout the day."
            }
          />
        </div>
        <div className="bg-white rounded-md p-1 mx-3 m-2">
          {isLoading ? (
            <div className="w-full grid justify-items-center content-center">
              <Loader />
            </div>
          ) : (
            <ResponsiveContainer
              className="grid bg-white rounded-md"
              width="98%"
              height="97%"
            >
              <div className="ml-5">
                <BarChartComponent
                  rows={chartRows}
                  selectedDataKey={"total_net_sales"}
                />
              </div>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
}

export default SecondSalesCard;

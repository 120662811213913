import { useNavigate } from "react-router-dom";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import { userStore, useUserStore } from "../../store/user";
import decodeJwt from "jwt-decode";
import { createUnauthenticatedApolloClient } from "../../providers/ApolloClientFactory";
import {OTP_SEND, VALIDATE_USER_QUERY} from "./queries";
import { LOGIN_MUTATION } from "./mutations";
import { ForgotBody, LoginBody, OTPSendResponse } from "./type";
import {customAlphabet} from "nanoid";


const HASURA_ENDPOINT = import.meta.env.VITE_SERVICE_HASURA_ENDPOINT;
if (!HASURA_ENDPOINT) throw new Error("missing VITE_SERVICE_HASURA_ENDPOINT");

export enum PERMISSIONS {
  ADMIN = "admin",
  ORGANIZER = "organizer",
  REPORTING = "reporting",
  MANAGER = "manager",
  VENDOR = "vendor",
  CLERK = "clerk",
  NONE = "none",
  EVENT_MANAGER = "event_manager",
  EVENT_REPORTING = "event_reporting",
  VENDOR_MANAGER = "vendor_manager",
  VENDOR_REPORTING = "vendor_reporting",
}

export const forgotPassword = async ({ username }: ForgotBody) => {
  let nanoid = customAlphabet("1234567890", 6);
  if (!username) {
    return { success: false, message: `Please enter a valid username.` };
  }
  try {
    const client = createUnauthenticatedApolloClient();
    const { data } = await client.query({
      query: VALIDATE_USER_QUERY,
      variables: { username },
    });

    if (data.users.length > 0) {
      const user = data.users[0];
      const email = user.email;
      const phone = user.phone_number;

      if (!email && !phone) {
        return { success: false, message: "No contact information associated with this account." };
      }
      let otp = nanoid();
      if (email) {
        const { data: otpData } = await client.mutate<OTPSendResponse>({
          mutation: OTP_SEND,
          variables: {
            otpSendInput: {
              id: user.id,
              username: user.username,
              email: user.email,
              phone: user.phone_number,
              otp: otp,
            },
          },
        });
        if (otpData?.otp_send?.success)  {
          return {
            id: user.id,
            email: user.email,
            success: true,
            message: "OTP sent successfully. Please check your email.",
            otp: otp,
          };
        }
        console.log("Email OTP send failed, attempting via SMS.");
      }

      if (phone) {
        const { data: otpData } = await client.mutate<OTPSendResponse>({
          mutation: OTP_SEND,
          variables: {
            otpSendInput: {
              id: user.id,
              username: user.username,
              email: user.email,
              phone: user.phone_number,
              otp: otp,
            },
          },
        });
        if (otpData?.otp_send?.success) {
          return {
            id: user.id,
            phone: user.phone_number,
            success: true,
            message: "OTP sent successfully. Please check your SMS.",
            otp: otp,
          };
        } else {
          return { success: false, message: "Failed to send OTP via SMS. " + otpData?.otp_send?.error, };
        }
      }

      return { success: false, message: "Failed to send OTP." };
    } else {
      return { success: false, message: "No account with that username exists." };
    }
  } catch (error) {
    console.error("Error processing forgot password request:", JSON.stringify(error, null, 2));
    return { success: false, message: `Failed to process forgot password request.` };
  }
};

export const login = async ({ username, password }: LoginBody) => {
  const client = createUnauthenticatedApolloClient();
  const { getState } = userStore;
  const { getState: getOrganizationState } = organizationStore;

  const setOrganizationId = getOrganizationState().setOrganizationId;
  const initOrganization = getOrganizationState().initOrganization;
  const fetchUser = getState().fetchUser;

  const { data } = await client.mutate({
    mutation: LOGIN_MUTATION,
    variables: {
      username,
      password,
    },
  });
  const accessToken = data.login_dashboard.accessToken;
  if (!accessToken) {
    return Promise.reject();
  }

  const decodedToken: {
    [key: string]: any;
  } = decodeJwt(accessToken);
  const hasuraClaims = decodedToken["https://hasura.io/jwt/claims"];
  if (!hasuraClaims) {
    return Promise.reject();
  }

  const allowedRoles = ["admin"];
  const organizationId = hasuraClaims["x-hasura-user-organization-id"];
  localStorage.setItem("ron-auth", accessToken);
  localStorage.setItem("permissions", allowedRoles[0]);
  setOrganizationId(organizationId);
  await fetchUser();
  await initOrganization();
  const hasuraDefaultRole = hasuraClaims["x-hasura-default-role"];
  if (hasuraDefaultRole) {
    sessionStorage.setItem("hasuraDefaultRole", hasuraDefaultRole);
  }
};

export const usePermissions = (moduleName?: string) => {
  const user = useUserStore((state) => state.user);
  const permissions: any = user?.role?.permissions ?? {};
  const moduleWise: any = moduleName ? permissions?.[moduleName] : [];
  const deletePermission = moduleWise?.find(
    (module: { label: string; value: string }) =>
      module?.value?.includes("_DELETE")
  );
  const editCreatePermission = moduleWise?.find(
    (module: { label: string; value: string }) =>
      module?.value?.includes("_EDIT")
  );
  const ListPermission = moduleWise?.find(
    (module: { label: string; value: string }) =>
      module?.value?.includes("_VIEW")
  );
  const SearchPermission = moduleWise?.find(
      (module: { label: string; value: string }) =>
          module?.value?.includes("_SEARCH")
  );
  return {
    permissions,
    moduleWisePermission: moduleWise,
    deletePermission,
    editCreatePermission,
    SearchPermission,
    ListPermission,
    user,
  };
  // (
  //       localStorage.getItem("permissions") ?? PERMISSIONS.NONE
  //     ) as (typeof PERMISSIONS)[keyof typeof PERMISSIONS])
};

export const useLogout = () => {
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);
  const initOrganization = useOrganizationStore(
    (state) => state.initOrganization
  );
  const setOrganizationId = useOrganizationStore(
    (state) => state.setOrganizationId
  );
  return [
    async () => {
      localStorage.removeItem("ron-auth");
      localStorage.removeItem("permissions");
      sessionStorage.removeItem("hasuraDefaultRole");
      setOrganizationId(999999999);
      initOrganization();
      setUser(undefined);
      navigate("/login");
    },
  ];
};

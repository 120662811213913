import { useState } from "react";
import {
  Form,
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useLoaderData,
  redirect,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Input } from "../../components/inputs/Input";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {
  UPDATE_ORGANIZATION_USER,
  UPDATE_ORGANIZATION_USER_NO_PASSWORD,
} from "./mutations";
import { UserByPK } from "./queries";
import { Vendor } from "../../types/vendor";
import { OrganizationUser } from "./type";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { Roles } from "../roles/type";
import { useUserStore, userStore } from "../../store/user";
const client = createApolloClient();
const { getState: getUserStore } = userStore;

export const profileAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const user = getUserStore().user;
  const body = await request.formData();

  if (!user?.id) {
    toast.error("User ID is not available.");
    return redirect(`/dashboard`);
  }

  const commonVariables = {
    id: user.id,
    email: body.get("email") || null,
    first_name: body.get("first_name") || null,
    last_name: body.get("last_name") || null,
    phoneNumber: body.get("phone_number") || null,
  };

  try {
    if (body.get("passwordUpdated") === "true") {
      await client.mutate({
        mutation: UPDATE_ORGANIZATION_USER,
        variables: {
          ...commonVariables,
          password_hash: body.get("password"),
        },
      });
    } else {
      await client.mutate({
        mutation: UPDATE_ORGANIZATION_USER_NO_PASSWORD,
        variables: commonVariables,
      });
    }
    toast.success("Profile updated");
    return redirect(`/dashboard`);
  } catch (error) {
    toast.error("Unable to update user: " + error);
  }
};

export const profileByIdLoader = async ({ params }: LoaderFunctionArgs) => {
  const userId = getUserStore().user?.id;

  const [{ data: usersData }] = await Promise.all([
    client.query({
      query: UserByPK,
      variables: { id: userId },
    }),
  ]);

  return {
    user: usersData?.users_by_pk,
  };
};

export const ProfileEdit = () => {
  const userData = useUserStore((state) => state.user);
  const { user } = useLoaderData() as {
    user: OrganizationUser;
    vendors: Vendor[];
    roles: Roles[];
  };
  const [passwordUpdate, setpasswordUpdate] = useState(false);
  return (
    <Form method="post" action={`/profile`}>
      <FormLayout>
        <div className="col-span-4">
          <span className="block text-sm font-medium text-gray-700">
            Username:
          </span>
          <span className="block text-md font-medium text-gray-700">
            {user.username}
          </span>
        </div>
        <div className="col-span-1">
          <IndeterminateCheckbox
            label="Update Password?"
            name="passwordUpdated"
            defaultChecked={false}
            onChange={(checked) => {
              setpasswordUpdate(checked as boolean);
            }}
          />
        </div>
        {passwordUpdate && (
          <div className="col-span-5">
            <Input label="Password" name="password" required />
          </div>
        )}
        <div className="col-span-6">
          <Input
            label="First Name"
            name="first_name"
            defaultValue={user.first_name}
          />
        </div>
        <div className="col-span-6">
          <Input
            label="Last Name"
            name="last_name"
            defaultValue={user.last_name}
          />
        </div>
        <div className="col-span-6">
          <Input
            label="Email"
            name="email"
            type="email"
            defaultValue={user.email}
          />
        </div>
        <div className="col-span-6">
          <Input
            label="Phone Number"
            name="phone_number"
            defaultValue={user.phone_number}
          />
        </div>
      </FormLayout>
    </Form>
  );
};

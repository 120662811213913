import { gql } from "@apollo/client";

export const VALIDATE_USER_QUERY = gql`
query validateUser($username: String) {
    users(
      where: {
        _or: [
          { username: { _eq: $username } },
        ]
      }
    ) {
      id
      username
      phone_number
      email
      username
      password_hash
      is_active
      tablet_access_code
      role {
        name
      }
    }
  }
`;

export const OTP_SEND = gql`
  mutation OTPSend($otpSendInput: otpSendInput!) {
    otp_send(otpSendInput: $otpSendInput) {
      message
      success
      error
    }
  }
`;